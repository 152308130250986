import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import Layout from 'shared/AnotherCourses/Layout';
import { HeaderWithPromotion } from 'shared/AnotherCourses/component/HeaderPage';
import { useBreadcrumbSchema } from 'shared/AnotherCourses/hooks/useBreadcrumbSchema';

const ListCourse = ({ location }) => {
  const breadCrumbList = useBreadcrumbSchema({ slug: 'toeic' });

  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister={false}
    >
      <MySEO
        slug={location.pathname}
        breadCrumbSchema={breadCrumbList}
        h1="TOEIC"
        featureImg="BCNq60sQFevzs2mBkuzi"
        title="Khóa Học TOEIC Cho Người Mất Gốc, Đạt 550+ TOIEC trong 6 tuần"
        description="Khóa học TOEIC tại DOL tập trung vào xây dựng kiến thức nền tảng, cho học viên làm quen với Linearthinking làm nền tảng tiếp thu những kiến thức chuyên sâu."
      />
      <HeaderWithPromotion level="TOEIC" />
      <Layout level="TOEIC" />
    </MainLayout>
  );
};

export default React.memo(ListCourse);
